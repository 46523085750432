<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.66666V3.33332M4 1.66666V3.33332"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.99659 10.8333H9.00406M8.99659 14.1666H9.00406M12.3262 10.8333H12.3337M5.66699 10.8333H5.67447M5.66699 14.1666H5.67447"
      stroke="#344054"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.91699 6.66666H16.0837"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.0835 10.2027C1.0835 6.57161 1.0835 4.75607 2.12693 3.62803C3.17036 2.5 4.84974 2.5 8.2085 2.5H9.79183C13.1506 2.5 14.83 2.5 15.8734 3.62803C16.9168 4.75607 16.9168 6.57161 16.9168 10.2027V10.6306C16.9168 14.2617 16.9168 16.0773 15.8734 17.2053C14.83 18.3333 13.1506 18.3333 9.79183 18.3333H8.2085C4.84974 18.3333 3.17036 18.3333 2.12693 17.2053C1.0835 16.0773 1.0835 14.2617 1.0835 10.6306V10.2027Z"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.5 6.66666H16.5"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '14',
    },
    width: {
      type: String,
      default: '12.25',
    },
    color: {
      type: String,
      default: 'icon-color-grey',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
